<template>
  <v-layout v-if="getMail" style="overflow-y: auto" class="px-4" full-width>
    <v-container>
      <v-layout flex class="my-5">
        <div>
          <v-list-item-title>
            <v-avatar
              size="30"
              class="grey--text mr-2"
              style="border: 1px solid"
            >
              <v-icon class="grey--text" dark> mdi-account </v-icon>
            </v-avatar>
            {{ routeName == "inbox" ? getMail.sender : getMail.receiver }}
          </v-list-item-title>
          <h2 class="font-weight-regular mt-2">{{ getMail.subject }}</h2>
        </div>

        <v-spacer></v-spacer>
        <div>
          {{ new Date(getMail.timestamp).toGMTString().split(" 2021")[0] }}
          <span class="text-uppercase">
            {{
              new Date(getMail.timestamp).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            }}
          </span>
        </div>
      </v-layout>
      <v-divider></v-divider>

      <template v-for="(item, i) in getMail.attachments">
        <v-card
          :key="i"
          width="max-content"
          :title="item.name"
          max-width="250px"
          outlined
          class="ma-3 atttachment-placer"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-1">{{ item.name }}</div>
              <v-list-item-title class="mb-1">
                {{ formatBytes(item.size) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              v-show="item.type.split('/').includes('image')"
              @click="viewFile(item)"
            >
              View
            </v-btn>
            <v-btn
              outlined
              rounded
              text
              @click="fileDownload(item.name, item.data)"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <v-divider></v-divider>

      <div class="mail-body mt-10">
        {{ getMail.body }}
      </div>

      <v-row>
        <div class="text-center">
          <v-dialog
            v-model="dialog"
            width="80vw"
            height="80vh"
            class="file-viewer"
          >
            <v-card :loading="iframeLoading">
              <v-card-title class="headline grey lighten-2">
                {{ iframe.name }}
              </v-card-title>

              <v-layout
                justify-center
                align-center
                fill-height
                class="file-viewer--content"
              >
                <img :src="iframe.src" alt="Attachment file" />
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="fileDownload(iframe.name, iframe.src)"
                >
                  Download
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-row>
    </v-container>
  </v-layout>
  <v-layout v-else>
    <v-container class="fill-height" fluid>
      <v-row justify="center" align="center">
        <v-col class="text-center">
          <v-btn disabled icon x-large target="_blank">
            <v-icon x-large>mdi-code-tags</v-icon>
          </v-btn>
          <div>
            <button disabled>Select an mail to read</button>
            <div class="grey--text">
              <button disabled>Nothing is selected</button>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      getMail: false,

      dialog: false,
      iframeLoading: true,
      showAttachments: false,
      attachments: [],
      iframe: {
        name: "",
        src: "",
      },
    };
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    routeName() {
      return this.$route.name;
    },
    showMails() {
      if (this.routeName == "inbox") return this.$store.getters.getInbox;
      else return this.$store.getters.getSent;
    },
  },
  async created() {
    if (this.$route.params.id) {
      const index = this.showMails.findIndex(
        (mail) => mail.uniqueid == this.$route.params.id
      );

      if (index > -1) {
        this.getMail = this.showMails[index];
        this.singleDecrypt(this.getMail);
      } else this.$router.push(`/mail/inbox`);
    }
  },
  methods: {
    singleDecrypt(mail) {
      if (!mail.is_decrypt) {
        this.$store.dispatch("DECRYPT_SINGLE", mail);
      }
    },
    decryptAndDownload(file) {
      const vueObj = this;
      this.$store.dispatch("GET_ATTACHMENTS", file.id).then((data) => {
        this.$store
          .dispatch("DECRYPT_MESSAGE", {
            cipherText: data.data.attachments[0].data,
            secret: vueObj.userInfo.decryptKey,
          })
          .then((decM) => {
            vueObj.fileDownload(file.name, decM);
          });
      });
    },
    fileDownload(name, data) {
      var fileToDownload = document.createElement("a");
      fileToDownload.href = data;
      fileToDownload.download = name;
      fileToDownload.click();
    },
    viewFile(file) {
      this.iframeLoading = true;
      this.dialog = true;

      this.iframe.name = file.name;
      this.iframe.src = file.data;
      this.iframeLoading = false;
    },
    formatBytes(a, b = 2) {
      if (0 === a) return "0 Bytes";
      const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));
      return (
        parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-body {
  white-space: pre-wrap;
}

.atttachment-placer {
  display: inline-block;
}

iframe {
  width: 100%;
  height: 100%;
  min-height: 60vh;
}

.file-viewer {
  width: 80vw;
  height: 80vh;
  position: relative;

  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 65vh;
    overflow: auto;

    img {
      height: auto;
      flex-shrink: 0;
      max-width: none;
      user-select: none;
      height: 100%;
    }
  }
}
</style>