<template>
  <v-layout full-width fill-height>
    <template v-if="!emptyMails">
      <inbox-list class="list-container"></inbox-list>
      <inbox-view :class="viewPortClass" full-width></inbox-view>
    </template>

    <template v-else>
      <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
          <v-col class="text-center">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="emptyMails = !emptyMails"
                  icon
                  large
                  target="_blank"
                  v-on="on"
                >
                  <v-icon large>mdi-code-tags</v-icon>
                </v-btn>
                <div class="grey--text">
                  <button disabled>You have no mails to show</button>
                </div>
              </template>
              <span>No transaction data</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-layout>
</template>

<script>
import InboxList from "../components/inbox-list.vue";
import InboxView from "../components/inbox-view.vue";

export default {
  data() {
    return {
      viewPortClass: "view-container",
    };
  },
  components: {
    InboxList,
    InboxView,
  },
  computed: {
    emptyMails() {
      return this.$store.getters.getInbox.length == 0 ? true : false;
    },
  },
  created() {
    if (this.$route.params.id && this.$route.query.show)
      this.viewPortClass = "view-container z-imp";
    else this.viewPortClass = "view-container";
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  width: 400px !important;
  background: white !important;
  z-index: 1;
}

.view-container {
  z-index: 0;
  background: white !important;
  width: 100% !important;
  position: absolute !important;
  height: 100%;
  padding-left: 400px !important;
}

@media (max-width: 768px) {
  .list-container {
    width: 100% !important;
  }

  .z-imp {
    z-index: 2 !important;
  }

  .view-container {
    padding-left: 15px !important;
  }
}
</style>